/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useEffect, useState } from 'react';
import useModalBox from '@base/utils/hooks/useModalBox';

import Image from '@base/parts/image';
import Button from '@base/parts/button';
import AnimatedBackground from '@base/elements/animated-background';
import ConditionalWrapper from '@base/utils/helpers/conditionnal-wrapper';
import Markdown from '@base/parts/markdown';

function PreorderDeluxe({ data, pageContext, globalData }) {
  const [modal, openModalBox] = useModalBox();
  const title = pageContext?.buyModalTitle;
  const subtitle = pageContext?.buyModalSubTitle;
  const modalData = {
    title,
    subtitle,
    link: 'base-deluxe',
  };

  const handleClick = () => {
    openModalBox(
      'buyGame',
      'modal-buy relative h-full w-full lg:h-5/6 lg:w-5/6 overflow-hidden',
      modalData,
    );
  };

  return (
    <section className='preorder-deluxe-section'>
      <AnimatedBackground
        className='preorder-deluxe-background relative left-0 top-0 z-0 h-full w-full overflow-hidden lg:absolute'
        translate={[0, -80]}
        offset={['0vh', '100vh']}>
        <AnimatedBackground
          className='preorder-deluxe-background-container relative left-0 top-0 z-0 flex h-full w-full justify-center lg:absolute'
          translate={[0, 80]}
          offset={['0vh', '100vh']}>
          <div
            className={`preorder-deluxe-video absolute right-0 top-0 hidden justify-center overflow-hidden lg:flex`}>
            <Image media={data.image} />
          </div>
        </AnimatedBackground>
      </AnimatedBackground>

      <div className='w-full overflow-hidden lg:relative'>
        <div className='flex h-full w-full flex-col lg:mr-auto lg:w-1/2 lg:min-w-[75rem] lg:px-16'>
          <div className='preorder-deluxe-section-content relative flex w-full flex-col'>
            <div className='w-full px-8 lg:px-0'>
              {(data.title || data.subtitle) && (
                <div className='relative left-0 mb-8 w-full md:top-0 md:w-auto'>
                  {data.title && (
                    <h2 className='multi-line h4 text-center lg:text-left'>
                      {data.title && (
                        <span className='pointer-events-auto left-0'>
                          {data.title}
                        </span>
                      )}
                      {data.title2 && (
                        <span className='pointer-events-auto right-0'>
                          {data.title2}
                        </span>
                      )}
                    </h2>
                  )}
                </div>
              )}

              {data.subtitle && (
                <Markdown className='mb-0 text-center font-body text-s tracking-wider text-white lg:mb-10 lg:text-left lg:text-base'>
                  {data.subtitle}
                </Markdown>
              )}
            </div>

            {data.imageMobile && (
              <div className='flex w-full lg:hidden'>
                <Image
                  media={data.imageMobile}
                  className='preorder-image-mobile w-full'
                />
              </div>
            )}

            <div className='w-full px-8 lg:px-0'>
              <div className='preorder-list flex flex-col items-center lg:items-start'>
                {data.text && (
                  <Markdown className='mb-3 text-left font-body text-xs tracking-wider text-white sm:-ml-16 lg:ml-0 lg:text-sm'>
                    {data.text}
                  </Markdown>
                )}

                {data.rows && data.rows.length > 0 && (
                  <ul className='pb-10 font-body text-xs leading-normal text-white lg:text-sm'>
                    {data.rows.map((row, i) => (
                      <li>
                        <Markdown>{row.text}</Markdown>
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              {data.keyart && (
                <div className={`${data.keyartMobile ? 'hidden lg:flex' : ''}`}>
                  <Image alt='preorder image' media={data.keyart} />
                </div>
              )}

              {data.keyartMobile && (
                <div className={`mx-auto flex max-w-3xl lg:hidden`}>
                  <Image
                    alt='preorder mobile image'
                    media={data.keyartMobile}
                  />
                </div>
              )}

              {data.text2 && (
                <p className='mx-auto w-full max-w-[66rem] pb-10 font-body text-xs leading-8 tracking-wide text-white lg:ml-0'>
                  {data.text2}
                </p>
              )}

              <ConditionalWrapper
                condition={data?.buttons.length > 1}
                // eslint-disable-next-line react/no-unstable-nested-components
                wrapper={(children) => (
                  <div className='flex w-full flex-col items-center justify-center md:flex-row md:space-x-14 lg:w-auto'>
                    {children}
                  </div>
                )}>
                <div className='flex w-full justify-center lg:justify-start'>
                  {data?.buttons?.map((button, i) => (
                    <Button
                      key={`ctaBtn-${i}`}
                      trigger={button?.trigger}
                      pageContext={pageContext}
                      className={button.className}
                      eventAction={button?.eventAction}
                      eventPage={pageContext.pageDataId}
                      eventSection='home preorder keyart'
                      gsEvent={button?.gsEvent}
                      trackable
                      onClick={handleClick}>
                      <span>{button?.text}</span>
                    </Button>
                  ))}
                </div>
              </ConditionalWrapper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PreorderDeluxe;

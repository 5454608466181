import React, { useContext, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import focusTrap from '@base/utils/helpers/focus-trap';

import Logo from '@elements/logo';
import FooterSmall from '@elements/footer-small';
import { GateContext } from '@/utils/contexts/gate-context';
import triggerPageView from '@/utils/helpers/trigger-page-view';
import ChoiceDial from './choice-dial';
import PageAgeGate from './age-gate';
import ChoicesBackground from './choices-background';
import NavStartPage from './nav';

function StartPage(props) {
  const startPage = useRef(null);
  const choiceDial = useRef(null);
  const { firstTime } = useContext(GateContext);
  const [isFirstTime, setFirstTime] = firstTime;
  const { pageContext } = props;
  const { globalData } = props;
  const data = globalData?.startPageData;

  useEffect(() => {
    setFirstTime(true);
  }, []);

  // Add class to body when Start page is active
  const startPageClass = (action) => {
    if (typeof document !== 'undefined') {
      const { body } = document;
      if (action === 'add') {
        body.classList.add('start-page-active');
      } else {
        body.classList.remove('start-page-active');
      }
    }
  };

  useEffect(() => {
    if (isFirstTime) {
      // Track Page Views
      const trackingPageName = 'dispatch_page';
      triggerPageView(trackingPageName, pageContext);

      // Trap Focus in modal box when navigating with keyboard
      if (startPage?.current) {
        focusTrap(startPage.current);
      }

      document.body.classList.add('full-page-locked');
      startPageClass('add');
    } else {
      document.body.classList.remove('full-page-locked');
      startPageClass('remove');
    }
  }, [isFirstTime]);

  return (
    <>
      <AnimatePresence>
        {isFirstTime && (
          <motion.section
            ref={startPage}
            className='start-page bg-secondary md:bg-black z-god fixed w-full h-screen overflow-auto left-0 top-0 text-white flex flex-col justify-start transition-all duration-300'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            tabIndex='-1'
            transition={{
              delay: 0,
              duration: 0.5,
            }}
          >
            <div className='h-full md:h-[calc(100vh-6.5rem)] min-h-auto md:min-h-[60rem] relative transition-all duration-300 flex flex-col justify-center pointer-events-none'>
              <NavStartPage
                pageContext={pageContext}
                globalData={globalData}
              />
              <div className='h-full md:h-auto w-full flex justify-between md:justify-center items-center flex-col pb-4 pt-28 lg:pt-16 md:pb-16 relative top-0'>
                <Logo
                  globalData={globalData}
                  className='mx-auto md:mb-20 pointer-events-none relative items-center w-full md:w-auto'
                />
                <h1 className='h3 reversed multi-line centered my-8 md:mb-20 md:mt-0'>
                  {data.title && (
                    <span className='pointer-events-auto'>{data.title}</span>
                  )}
                  {data.title2 && (
                    <span className='pointer-events-auto'>{data.title2}</span>
                  )}
                </h1>
                <ChoiceDial
                  ref={choiceDial}
                  pageContext={pageContext}
                  data={data.choices}
                />
                <div className='flex md:hidden' />
              </div>
              <ChoicesBackground
                pageContext={pageContext}
                data={data.choices}
              />
            </div>
            <FooterSmall
              pageContext={pageContext}
              globalData={globalData}
              className='relative'
            />
          </motion.section>
        )}
      </AnimatePresence>
      <PageAgeGate
        data={props.ageGateData}
        pageContext={pageContext}
        globalData={globalData}
      />
    </>
  );
}
export default StartPage;

import React, { useEffect, useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import SVG from 'react-inlinesvg';

import useWindowSize from '@base/utils/hooks/useWindowSize';
import Heading from '@base/parts/heading';
import Markdown from '@base/parts/markdown';
import Button from '@base/parts/button';
import AnimatedBackground from '@base/elements/animated-background';
import Image from '@base/parts/image';

import expandIcon from '@img/icons/expand.svg';
import shrinkIcon from '@img/icons/shrink.svg';


function CtaBlock({ data, pageContext, globalData }) {
  const [isActive, setActive] = useState(false);

  // Set Mobile State
  const [isMobile, setIsMobile] = useState(null);
  const windowSize = useWindowSize();
  useEffect(() => {
    if (windowSize.width <= 991) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [windowSize]);

  const toggleSection = (e) => {
    const isTargetBtn = e.target.className.includes('button');
    if (isMobile && !isTargetBtn) {
      setActive(!isActive);
    }
  };

  return (
    <section
      className={`cta-section relative w-full ${isActive ? 'active' : ''}`}
      onClick={toggleSection}
    >
      <div className='flex justify-center'>
        <div className='custom-content z-1 flex justify-center'>
          <div className='cta-content flex w-full flex-col items-center'>
            <Heading
              text={data.title}
              tag='h3'
              className='mb-4 text-center lg:mb-32'
            />
            <div className='mb-20 flex w-full justify-end lg:hidden'>
              <div className='btn-mobile-expand flex rotate-[8deg] items-center pointer-events-none h-16'>
                <span className='font-script text-sm font-bold uppercase pr-16'>
                  {globalData?.stringsActions?.click || 'Click'}
                </span>
                <SVG cacheRequests src={expandIcon} id='expand-icon' className='absolute right-0 top-0' />
                <SVG cacheRequests src={shrinkIcon} id='shrink-icon' className='absolute right-0 top-0' />
              </div>
            </div>
            <div className='flex flex-col md:flex-row md:space-x-24 lg:space-x-36'>
              {data?.rows?.map((element, i) => (
                <div className='cta-element'>
                  <div className='cta-image'>
                    <Image
                      media={element?.image}
                      alt={element?.alt}
                      className='image cta-polaroid w-full'
                    />
                    <StaticImage
                      src='../../assets/img/ui/polaroid-tape.png'
                      alt=''
                      placeholder='blurred'
                      loading='lazy'
                      className='gatsby-absolute -top-6 left-1/2 w-[35%] -translate-x-1/2'
                      role='presentation'
                    />
                    <div className='cta-image-new text-base font-bold uppercase'>
                      <span>{element?.text}</span>
                    </div>
                  </div>

                  <Markdown className='gatsby-hidden-mobile light-bg mb-8 mt-16 text-center text-base font-bold'>
                    {element?.title}
                  </Markdown>

                  <div className='mt-12 flex lg:mt-0'>
                    <Button
                      link={element.button?.link}
                      key={`ctaBtn-${i}`}
                      trigger={element.button?.trigger}
                      pageContext={pageContext}
                      className={element.button?.className}
                      eventAction={element.button?.eventAction}
                      eventPage={pageContext.pageDataId}
                      eventSection='home cta section'
                    >
                      <span>{element.button?.text}</span>
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <AnimatedBackground
        className='pointer-events-none absolute left-0 top-0 z-under h-full w-full'
        translate={[0, 0]}
        offset={['-100vh', '100vh']}
      >
        <StaticImage
          src='../../assets/img/backgrounds/cta-background-map.jpg'
          alt=''
          placeholder='blurred'
          loading='lazy'
          className='cta-section-background gatsby-hidden-mobile'
          role='presentation'
        />
        <StaticImage
          src='../../assets/img/backgrounds/cta-mobile-background-map.jpg'
          alt=''
          placeholder='blurred'
          loading='lazy'
          className='cta-section-background gatsby-hidden-desktop'
          role='presentation'
        />
      </AnimatedBackground>
    </section>
  );
}

export default CtaBlock;

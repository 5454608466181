import React, {useRef} from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import SVG from 'react-inlinesvg';

import Markdown from '@base/parts/markdown';
import Image from '@base/parts/image';
import Button from '@base/parts/button';
import plundererVideoMp4 from '@assets/videos/plunderers-instinct.mp4';
import playBtn from '@img/icons/play-btn.svg';

function GameModal({ data, type, strings, inclusions }) {
  const videoRef = useRef(null);
  const videoWrapperRef = useRef(null);

  const handleClick = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      videoWrapperRef.current.className = 'isPlaying';
    } else {
      videoRef.current.pause();
      videoWrapperRef.current.className = '';
    }
  };

  return (
    <div
      className={`modal-game modal-game-${data.gameID} modal-type-${data.type}`}>
      <div className='modal-game-content'>
        {type === 'preorder' && (
          <>
            <div className='flex max-w-[68rem] flex-col px-10'>
              {strings?.cta?.preOrderToReceive && (
                <h3 className='text-center text-sm lg:text-[2.4rem]'>
                  <span>{strings.cta.preOrderToReceive}</span>
                </h3>
              )}
              {data.preOrderBonus?.map((bonus, i) => (
                <div key={`gameBonus_${i}`}>
                  <h3 className='mt-4 rotate-[1deg] text-center text-sm lg:mt-8 lg:text-[2.4rem]'>
                    <span>{`${bonus.title} ${bonus.subtitle}`}</span>
                  </h3>
                  <p className='mt-20 text-s leading-normal'>
                    {bonus.description}
                  </p>
                </div>
              ))}
            </div>
            <div className='preorder-video relative max-w-[80%]'>
              <div ref={videoWrapperRef}>
                <Button
                  id='preorder-modal-trailer-button'
                  key='preorder-modal-trailer-button'
                  onClick={handleClick}>
                  <SVG
                    cacheRequests
                    src={playBtn}
                    preProcessor={(code) =>
                      code.replace(/fill="#6f777c"/g, 'fill="currentColor"')
                    }
                    className='play-icon pointer-events-none text-white'
                  />
                </Button>
              </div>

              <video
                ref={videoRef}
                className='h-full w-full'
                playsInline
                loop
                src={plundererVideoMp4}
                type='video/mp4'></video>
            </div>
          </>
        )}
        {type === 'editions' && (
          <div className='flex max-w-none flex-col px-10 lg:px-24'>
            <div className='mx-auto mb-10 max-w-[39rem]'>
              <Image
                media={data?.keyart}
                alt={data?.title}
                className='w-full'
              />
              <h2 className='-mt-6 text-center text-lg'>
                <span>{data.title}</span>
              </h2>
            </div>
            <h3 className='plain with-separator mb-8 w-full text-left !text-base'>
              {data.gameID === 'base-deluxe' && (
                <span>{strings.labels.includedInDeluxe}</span>
              )}
              {data.gameID === 'base-standard' && (
                <span>{strings.labels.includedInStandard}</span>
              )}
            </h3>
            <ul className='px-10 text-sm'>
              {inclusions?.standard && inclusions?.deluxe ? (
                <>
                  {inclusions?.standard?.[0].map((inclusion, l) => (
                    <li key={`gameBaseInclusion_${l}`}>
                      {inclusion.title}
                      {inclusion.description && (
                        <span className='mt-4'>{inclusion.description}</span>
                      )}
                    </li>
                  ))}
                  {inclusions?.deluxe?.[0].map((inclusion, l) => (
                    <li key={`gameDeluxeInclusion_${l}`}>
                      {inclusion.title}
                      {inclusion.description &&
                        inclusion.type !== 'under' && (
                          <span className='mt-4 leading-normal'>
                            {inclusion.description}
                          </span>
                        )}
                      {inclusion?.image && (
                        <div className={`inclusion-element-${l} mb-16 mt-16`}>
                          <div className='image-wrapper'>
                            <div className={`${inclusion.imageMobile ? 'hidden lg:flex' : ''}`}>
                              <Image
                                alt={`deluxe image ${l}`}
                                media={inclusion.image}
                              />
                            </div>
                            {inclusion.imageMobile && (
                              <div className='flex lg:hidden max-w-xl mx-auto'>
                                <Image
                                  alt={`deluxe image mobile ${l}`}
                                  media={inclusion.imageMobile}
                                />
                              </div>
                            )}
                          </div>
                          {inclusion.description &&
                            inclusion.type == 'under' && (
                              <span className='mt-4 leading-normal'>
                                {inclusion.description}
                              </span>
                            )}
                        </div>
                      )}
                    </li>
                  ))}
                </>
              ) : (
                data.inclusions?.map((inclusion, l) => (
                  <li key={`gameInclusion_${l}`}>
                    {inclusion.title}
                    {inclusion.description && (
                      <span className='mt-4 leading-normal'>
                        {inclusion.description}
                      </span>
                    )}
                  </li>
                ))
              )}
            </ul>
          </div>
        )}
        {type === 'rewards' && (
          <div className='flex max-w-[60rem] flex-col px-10 lg:px-4'>
            <h3 className='forward text-center'>
              <span>{data.title}</span>
            </h3>
            {strings?.labels?.dbdOwnerRewards2 && (
              <h3 className='mb-12 mt-4 text-center lg:mt-8'>
                <span>{strings.labels.dbdOwnerRewards2}</span>
              </h3>
            )}
            <div className='mx-auto mb-12 max-w-[39rem]'>
              <StaticImage
                src='../../assets/img/rewards/dbd-keyart.jpg'
                alt='Dead by Daylight'
                className='w-full'
                placeholder='blurred'
                loading='eager'
              />
            </div>
            {strings?.labels?.dbdOwnerRewards && (
              <h3 className='plain with-separator mb-12 w-full text-left !text-base'>
                <span>{strings.labels.dbdOwnerRewards}</span>
              </h3>
            )}
            <ul className='flex flex-row flex-wrap gap-x-8 px-0 pb-12 lg:py-12'>
              {data.dbdRewards?.map((reward, i) => (
                <li
                  key={`gameReward_${i}`}
                  className={`modal-game-reward modal-game-reward-${reward.id} pl-8`}>
                  <img
                    src={reward?.image?.publicURL}
                    alt={reward?.title}
                    className='modal-game-reward-image'
                    style={{
                      shapeOutside: `url(${reward?.image?.publicURL})`,
                    }}
                  />
                  <Markdown className='modal-game-reward-title'>
                    {reward.title}
                  </Markdown>
                </li>
              ))}
            </ul>
            <p className='text-s'>{strings?.labels?.rewardsDisclaimer}</p>
          </div>
        )}
      </div>
      <div className='modal-decoration-left' />
      <div className='modal-decoration-right' />
    </div>
  );
}

export default GameModal;

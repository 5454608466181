import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Logo from '@elements/logo';
import CrmForm from '@elements/crm/crm-form';

function ModalSignUp({ globalData, pageContext }) {
  return (
    <div className='modal-crm-container'>
      <div className='modal-crm-header'>
        <Logo
          globalData={globalData}
          className='w-full max-w-[28rem] items-center md:max-w-[40rem] lg:max-w-[50rem]'
        />
        <div className='modal-crm-header-bg' />
      </div>
      <div className='modal-crm-content-wrapper'>
        <CrmForm
          globalData={globalData}
          pageContext={pageContext}
          type='modal'
        />
        <div className='modal-crm-background'>
          <StaticImage
            src='../../../assets/img/backgrounds/modal-light-background.jpg'
            alt=''
            placeholder='blurred'
            loading='lazy'
            className=''
            role='presentation'
          />
        </div>
      </div>
    </div>
  );
}

export default ModalSignUp;

import React, { useContext } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { GateContext } from '@/utils/contexts/gate-context';
import routeSelector from '@/utils/helpers/route-selector';

function ChoicesBackground(props) {
  const { data } = props;
  const { pageContext } = props;
  const { active, selectRoute, mobileState } = useContext(GateContext);
  const [isMobile, setIsMobile] = mobileState;
  const [selectedRoute, setSelectedRoute] = selectRoute;
  const [activeSelection, setActiveSelection] = active;
  const experimentChoiceData = data.find((x) => x.id === 'experiment');
  const websiteChoiceData = data.find((x) => x.id === 'website');

  const routeSelectorHandler = routeSelector();

  // Choice highlight effect
  const choiceHandler = (choice, status) => {
    if (status === 'enter') {
      setSelectedRoute(choice);
    }
    if (status === 'leave') {
      setSelectedRoute(null);
    }
  };

  return (
    <div className='choice-background-wrapper md:absolute left-0 md:top-0 z-under w-full h-full hidden md:flex flex-col md:flex-row justify-between pointer-events-auto'>
      <div
        className={`choice-background choice-background-left w-full md:w-[48%] h-1/2 md:h-full cursor-pointer flex justify-end order-2 md:order-1
          ${selectedRoute === 'experiment' ? 'active' : (selectedRoute !== null ? 'inactive' : '')}
          ${activeSelection === 'experiment' ? 'stay-active' : ''}
        `}
        onClick={() => routeSelectorHandler('experiment', experimentChoiceData, isMobile, pageContext)}
        onMouseEnter={() => choiceHandler('experiment', 'enter')}
        onMouseLeave={() => choiceHandler('experiment', 'leave')}
      >
        <StaticImage
          src='../../../assets/img/dispatch-page-choice-interactive.jpg'
          alt='Investigate Cedar Steel'
          className='w-[80%] md:w-full h-full'
          placeholder='#000000'
          loading='eager'
        />
      </div>
      <div className='choice-separator' />
      <div
        className={`choice-background choice-background-right w-full md:w-[48%] h-1/2 md:h-full cursor-pointer flex justify-start md:justify-end order-1 md:order-2
        ${selectedRoute === 'website' ? 'active' : (selectedRoute !== null ? 'inactive' : '')}
        ${activeSelection === 'website' ? 'stay-active' : ''}  
      `}
        onClick={() => routeSelectorHandler('website', websiteChoiceData, isMobile, pageContext)}
        onMouseEnter={() => choiceHandler('website', 'enter')}
        onMouseLeave={() => choiceHandler('website', 'leave')}
      >
        <StaticImage
          src='../../../assets/img/dispatch-page-choice-website.jpg'
          alt='The Casting of Frank Stone Website'
          className='w-full h-full -ml-[20%] md:ml-0'
          placeholder='#000000'
          loading='eager'
        />
      </div>
    </div>
  );
}
export default ChoicesBackground;

import React, { useEffect, useState, useRef } from 'react';
import SVG from 'react-inlinesvg';

import Button from '@base/parts/button';

import letterIcon from '@img/icons/letter.svg';
import CrmModal from '@elements/crm/modal-crm';

function Crm(props) {
  const [btnVisible, setBtnVisible] = useState(false);
  const { pageContext } = props;
  const { globalData } = props;
  const crmBtn = useRef(null);
  let totalHeight = null;

  useEffect(() => {
    if (pageContext.slug == '') {
      const nav = document.getElementsByClassName('header-main');
      const zoneHero = document.getElementsByClassName('hero-section');
      totalHeight = nav[0]?.clientHeight + zoneHero[0]?.clientHeight;
  
      const handleScroll = () => {
        setCrmBtnVisibility();
      };
      setCrmBtnVisibility();
  
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    } else {
      setBtnVisible(true);
    }
  }, []);

  const modalContent = () => (
    <CrmModal pageContext={pageContext} globalData={globalData} />
  );

  const setCrmBtnVisibility = () => {
    const limit = window.innerHeight + window.scrollY - crmBtn.current.clientHeight;

    if (totalHeight && totalHeight > limit) {
      setBtnVisible(false);
    } else {
      setBtnVisible(true);
    }
  };

  return (
    <div
      ref={crmBtn}
      className={`crm-btn fixed bottom-24 right-4 z-10 ${btnVisible ? '' : 'inactive'}`}
    >
      <Button
        trigger='dynamicModal'
        pageContext={pageContext}
        className='btn-crm'
        modalContent={{
          content: modalContent,
        }}
        trackable
        eventAction='newsletter_sticky_tab'
        eventPage={pageContext.pageDataId}
      >
        <SVG
          cacheRequests
          preProcessor={(code) => code.replace(/stroke=".*?"/g, 'stroke="currentColor"')}
          src={letterIcon}
          className='play-icon'
        />
      </Button>
    </div>
  );
}

export default Crm;

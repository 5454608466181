import React, { useEffect, useState, useContext } from 'react';
import { AnimatePresence, useReducedMotion } from 'framer-motion';
import { StaticImage } from 'gatsby-plugin-image';
import { useInView } from 'react-intersection-observer';

import { PageContext } from '@base/utils/contexts/page-context';
import useWindowSize from '@base/utils/hooks/useWindowSize';
import Heading from '@base/parts/heading';
import Button from '@base/parts/button';
import AnimatedBackground from '@base/elements/animated-background';
import Image from '@base/parts/image';
import Markdown from '@base/parts/markdown';

function FeaturesBlock({ data, pageContext, globalData }) {
  const prefersReducedMotion = useReducedMotion();
  const { modalState } = useContext(PageContext);
  const [modalOpen, setModalOpen] = modalState;

  // Set Mobile State
  const [isMobile, setIsMobile] = useState(0);
  const windowSize = useWindowSize();
  useEffect(() => {
    if (windowSize.width <= 991) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [windowSize]);

  return (
    <section className={`features-section flex-col ${pageContext?.locale}`}>
      <AnimatePresence>
        <div className='custom-content relative z-1'>
          <div className='relative z-1 pb-8 pt-8 text-center'>
            <Heading
              text={data.title}
              tag='h3'
              className='features-title with-separator'
            />
          </div>

          {/* <div className='feature-content grid grid-cols-3 gap-12 pb-24'> */}
          <div className='feature-content flex w-full justify-center pt-16'>
            {data.rows.map((row, i) => (
              <TextImageRow
                key={`textimage-row-${i}`}
                data={row}
                index={i}
                prefersReducedMotion={prefersReducedMotion}
              />
            ))}
          </div>

          <div className='flex w-full justify-center'>
            {data?.buttons?.map((button, i) => (
              <Button
                link={button.link}
                key={`btn-feature-${i}`}
                trigger={button?.trigger}
                pageContext={pageContext}
                className={button.className}
                eventAction={button?.eventAction}
                eventPage={pageContext.pageDataId}
                eventSection='feature boutons'
                gsEvent={button?.gsEvent}
                trackable
                videoSettings={{
                  player: button?.player || 'youtube',
                  loop: true,
                  ratingTimeBuffer: button?.ratingTimeBuffer,
                }}>
                <span>{button?.text}</span>
              </Button>
            ))}
          </div>
        </div>
      </AnimatePresence>

      <AnimatedBackground
        className='features-background relative left-0 top-0 z-under h-full w-full overflow-hidden bg-black lg:absolute'
        translate={[0, isMobile ? 0 : -80]}
        offset={['0vh', '100vh']}>
        <AnimatedBackground
          className={`features-background-container relative left-0 top-0 z-0 flex h-full w-full justify-center lg:absolute ${!prefersReducedMotion ? 'mt-0' : 'mt-0 lg:-mt-32'}`}
          translate={[0, isMobile ? 0 : 100]}
          offset={['0vh', '100vh']}>
          <div className='hidden md:block'>
            <StaticImage
              src='../../assets/img/backgrounds/features-background.jpg'
              alt=''
              className='features-background gatsby-absolute left-0 top-0 h-full w-full overflow-hidden'
              placeholder='blurred'
              loading='eager'
              role='presentation'
            />
          </div>
          <div className='block md:hidden'>
            <StaticImage
              src='../../assets/img/backgrounds/features-background-mobile.jpg'
              alt=''
              className='features-background gatsby-absolute left-0 top-0 h-full w-full overflow-hidden'
              placeholder='blurred'
              loading='eager'
              role='presentation'
            />
          </div>
        </AnimatedBackground>
      </AnimatedBackground>
    </section>
  );
}

function TextImageRow({ data, index, prefersReducedMotion }) {
  const [ref, inView] = useInView({
    threshold: 0.75,
    triggerOnce: true,
  });

  return (
    <div
      ref={ref}
      className='feature-row relative flex pb-10 md:pb-16'
      key={`feature-row-${index}`}>
      <div className='feature-row-title flex min-h-[6rem] items-center'>
        <div className='relative mx-auto flex flex-row items-center justify-center'>
          <p className='index h4 -mt-2 text-green'>
            <span>#{index + 1}</span>
          </p>
          {data.title && (
            <Heading text={data.title} tag='h5' className='h4 forward z-10' />
          )}
        </div>
      </div>

      <div className='feature-row-container relative mt-8'>
        <div className='feature-row-content'>
          <div
            className={`feature-row-image ${inView && !prefersReducedMotion ? 'in-view' : ''} ${prefersReducedMotion && 'no-animation'}`}>
            <div className='feature-row-image-content polaroid'>
              <Image
                media={data?.image}
                alt={data?.title}
                className='image w-full'
              />
              <StaticImage
                src='../../assets/img/content/void.jpg'
                alt=''
                placeholder='blurred'
                loading='lazy'
                className='image-border'
                role='presentation'
              />
            </div>
          </div>

          <div className='feature-row-text flex items-start pt-12'>
            <Markdown className='text-left font-body text-s leading-snug text-white lg:text-sm'>
              {data.text}
            </Markdown>
          </div>
        </div>
        <div className='feature-row-bg absolute bottom-0 left-0 -z-1 w-full'></div>
      </div>
    </div>
  );
}

export default FeaturesBlock;

import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Image from '@base/parts/image';
import Heading from '@base/parts/heading';
import Markdown from '@base/parts/markdown';
import Button from '@base/parts/button';
import AnimatedBackground from '@base/elements/animated-background';
import ConditionalWrapper from '@base/utils/helpers/conditionnal-wrapper';

function HighlightBlock({ data, pageContext, globalData }) {
  return (
    <section className='highlight-section relative w-full'>
      <div className='custom-content z-1 flex justify-center'>
        <div className='highlight-content flex w-full max-w-[78rem] flex-col items-center'>
          {data.title && (
            <Heading
              text={data.title}
              tag='h2'
              className='h3 mb-16 text-center'
            />
          )}
          {data.text && (
            <Markdown className='light-bg mb-12 text-center text-sm lg:text-base'>
              {data.text}
            </Markdown>
          )}
          <ConditionalWrapper
            condition={data?.buttons.length > 1}
            // eslint-disable-next-line react/no-unstable-nested-components
            wrapper={(children) => (
              <div className='flex items-center justify-center space-x-14'>
                {children}
              </div>
            )}
          >
            {data?.buttons?.map((button, i) => (
              <Button
                link={button.link}
                key={`highlightBtn-${i}`}
                trigger={button?.trigger}
                pageContext={pageContext}
                className={button.className}
                eventAction={button?.eventAction}
                eventPage={pageContext.pageDataId}
                eventSection='home cta section'
              >
                <span>{button?.text}</span>
              </Button>
            ))}
          </ConditionalWrapper>
        </div>
      </div>
      <div className='highlight-section-background pointer-events-none absolute left-0 top-0 z-0 h-full w-full'>
        <div className='highlight-section-background-img' />
      </div>
    </section>
  );
}

export default HighlightBlock;

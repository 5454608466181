import { useContext, useEffect, useCallback, useState } from 'react';
import { navigate } from 'gatsby';
import { useCookies } from 'react-cookie';

import { GeolocalizationContext } from '@base/utils/contexts/geolocalization-context';
import { LoadingContext } from '@base/utils/contexts/loader-context';
import { getPageURL } from '@base/utils/helpers/page-url';
import triggerEventClick from '@base/utils/helpers/tracking/trigger-event-click';

import { GateContext } from '@/utils/contexts/gate-context';
import triggerPageView from '@/utils/helpers/trigger-page-view';
import ageValidation from '@/utils/helpers/age-validation';

const routeSelector = () => {
  const [cookies, setCookie] = useCookies();
  const currentDomain = `${getPageURL('domain')}`;
  const cookieName = 'cofs-ftv';
  const ageGateCookieName = 'cofs-age-validation';

  const { loading } = useContext(LoadingContext);
  const [isLoading, setLoading] = loading;

  const { firstTime, selectRoute } = useContext(GateContext);
  const [isFirstTime, setFirstTime] = firstTime;
  const [selectedRoute, setSelectedRoute] = selectRoute;
  const [selectedRouteUrl, setSelectedRouteUrl] = useState(null);

  const { ageGate, legalAge, formSubmit } = useContext(GeolocalizationContext);
  const [ageGateSettings, setAgeGateSettings] = ageGate;
  const [isLegalAge, setLegalAge] = legalAge;
  const [formSubmitted, setFormSubmitted] = formSubmit;

  const ageGateHandler = ageValidation();

  const routeSelectorHandler = useCallback(
    (route, data, isMobile, pageContext) => {
      const routeUrl = data.url;
      setSelectedRoute(route);
      setSelectedRouteUrl(routeUrl);

      // Send tracking data to the dataLayer
      const dataLayer = {
        pageContext,
        event:
          data?.id === 'website'
            ? 'the_website'
            : data?.id === 'experiment'
              ? 'the_experiment'
              : data?.eventAction,
        page: 'dispatch_page',
        section: 'dispatch keyart',
      };
      triggerEventClick(dataLayer);

      setTimeout(
        () => {
          /* if (!cookies[ageGateCookieName] && ageGateSettings.enabled) {
        // Validate Age
        ageGateHandler(route)
        // Track Page View
        triggerPageView('age_gate', pageContext)
      } else {
        routeRedirect(routeUrl, pageContext)
      } */
          routeRedirect(routeUrl, pageContext);
        },
        isMobile ? 0 : 0,
      );
    },
  );

  // Redirect visitor to selected route
  const routeRedirect = (routeUrl) => {
    setLoading(true);

    /*     if (isFirstTime) {
      setCookie(cookieName, true, { domain: `${currentDomain}`, path: '/' })
    } */

    setFirstTime(false);

    document.body.classList.remove('full-page-locked');
    navigate(routeUrl);
  };

  // Redirect visitor when age gate is submitted
  useEffect(() => {
    if (formSubmitted && isLegalAge) {
      routeRedirect(selectedRouteUrl);
    }
  }, [formSubmitted]);

  return routeSelectorHandler;
};
export default routeSelector;

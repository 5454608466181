import React, { useEffect, useState, useContext } from 'react';
import { AnimatePresence, motion, useReducedMotion } from 'framer-motion';
import SVG from 'react-inlinesvg';
import { StaticImage } from 'gatsby-plugin-image';
import { useInView } from 'react-intersection-observer';
import { Video } from '@bhvr/web-design-system';

import { PageContext } from '@base/utils/contexts/page-context';
import { GeolocalizationContext } from '@base/utils/contexts/geolocalization-context';
import useWindowSize from '@base/utils/hooks/useWindowSize';
import Heading from '@base/parts/heading';
import Button from '@base/parts/button';
import AnimatedBackground from '@base/elements/animated-background';
import Image from '@base/parts/image';

import arrow from '@img/icons/arrow.svg';
import playBtn from '@img/icons/play-btn.svg';

function GameplayBlock({ data, pageContext, globalData }) {
  const prefersReducedMotion = useReducedMotion();
  const { modalState } = useContext(PageContext);
  const [modalOpen, setModalOpen] = modalState;

  const { ageGate } = useContext(GeolocalizationContext);
  const [ageGateSettings, setAgeGateSettings] = ageGate;

  // Set Mobile State
  const [isMobile, setIsMobile] = useState(0);
  const windowSize = useWindowSize();
  useEffect(() => {
    if (windowSize.width <= 991) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [windowSize]);

  return (
    <section className={`gameplay-section flex-col ${pageContext?.locale}`}>
      <AnimatePresence>
        <div className='custom-content gameplay-content relative z-1'>
          <div className='gameplay-header'>
            {data.title && (
              <Heading
                text={data.title}
                tag='h1'
                className='h2 lg:forward mb-12 text-center leading-tight lg:mb-8'
              />
            )}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              className='gameplay-video-container relative'
            >
              {!prefersReducedMotion && (!ageGateSettings.displayed || !ageGateSettings.enabled) && (
                <Video
                  bgLoop
                  className='gameplay-video relative left-0 top-0 hidden h-full w-full bg-black lg:block'
                  player='youtube'
                  source={data.trailer?.url}
                  rating={null}
                  ratingTimeBuffer={2000}
                />
              )}
              <StaticImage
                src='../../assets/img/keyart-poster.jpg'
                alt=''
                placeholder='blurred'
                loading='lazy'
                className={`gameplay-img h-full w-full bg-black ${!prefersReducedMotion && (!ageGateSettings.displayed || !ageGateSettings.enabled) ? 'gatsby-hidden-desktop' : ''}`}
                role='presentation'
              />
              <Button
                id='videoBtn-gameplay'
                link={data.trailer?.url}
                trigger='videoModal'
                key='videoBtn-gameplay'
                pageContext={pageContext}
                overwrite
                className='video-button absolute left-0 top-0 flex h-full w-full cursor-pointer flex-col items-center justify-center'
                trackable
                eventAction='watch_trailer'
                eventPage={pageContext.pageDataId}
                eventSection={data.trailer?.eventSection}
                videoSettings={{
                  player: 'youtube',
                  loop: true,
                  ratingTimeBuffer: 1,
                }}
              >
                <SVG
                  cacheRequests
                  src={playBtn}
                  preProcessor={(code) => code.replace(/fill="#6f777c"/g, 'fill="currentColor"')}
                  className='play-icon pointer-events-none text-white'
                />
                {data.subtitle && (
                  <p className='gatsby-hidden-mobile pointer-events-none mt-4 text-white'>
                    {data.subtitle}
                  </p>
                )}
              </Button>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5, delay: 0.5 }}
              className='gameplay-learnmore hidden md:block'
            >
              <p>{globalData.stringsActions?.scroll || 'Scroll'}</p>
              <SVG
                cacheRequests
                preProcessor={(code) => code.replace(/stroke=".*?"/g, 'stroke="currentColor"')}
                src={arrow}
              />
            </motion.div>
          </div>
          <div className='gameplay-gallery-container'>
            {data.title2 && (
              <Heading
                text={data.title2}
                tag='h3'
                className='with-separator mb-16 text-center'
              />
            )}

            <div className='flex flex-col md:flex-row md:space-x-16 md:pb-20'>
              {data.rows.map((row, i) => (
                <TextImageRow
                  key={`textimage-row-${i}`}
                  data={row}
                  index={i}
                  prefersReducedMotion={prefersReducedMotion}
                />
              ))}
            </div>
          </div>
        </div>
      </AnimatePresence>
      <AnimatedBackground
        className='gameplay-background relative left-0 top-0 z-under h-full w-full overflow-hidden bg-black lg:absolute'
        translate={[0, isMobile ? 0 : -80]}
        offset={['0vh', '100vh']}
      >
        <AnimatedBackground
          className={`gameplay-background-container relative left-0 top-0 z-0 flex h-full w-full justify-center lg:absolute ${!prefersReducedMotion ? 'mt-0' : 'mt-0 lg:-mt-32'}`}
          translate={[0, isMobile ? 0 : 100]}
          offset={['0vh', '100vh']}
        >
          <StaticImage
            src='../../assets/img/backgrounds/gameplay-background.jpg'
            alt=''
            className='gameplay-background gatsby-absolute left-0 top-0 h-full w-full overflow-hidden'
            placeholder='blurred'
            loading='eager'
            role='presentation'
          />
        </AnimatedBackground>
      </AnimatedBackground>
    </section>
  );
}

function TextImageRow({ data, index, prefersReducedMotion }) {
  const [ref, inView] = useInView({
    threshold: 0.75,
    triggerOnce: true,
  });

  return (
    <div
      ref={ref}
      className='gameplay-row flex-1 flex-col items-center pb-24 md:pb-12 md:pt-12'
      key={`gameplay-row-${index}`}
    >
      <div
        className={`gameplay-row-image ${inView && !prefersReducedMotion ? 'in-view' : ''} ${prefersReducedMotion && 'no-animation'}`}
      >
        <div className='gameplay-row-image-content polaroid'>
          {data.title && (
            <Heading
              text={data.title}
              tag='h5'
              className='h4 absolute bottom-0 left-0 z-10'
            />
          )}
          <Image
            media={data?.image}
            alt={data?.title}
            className='image w-full'
          />
          <StaticImage
            src='../../assets/img/content/void.jpg'
            alt=''
            placeholder='blurred'
            loading='lazy'
            className='image-border'
            role='presentation'
          />
        </div>
      </div>
      <div className='gameplay-row-text flex items-center pt-12 sm:pt-20 md:pt-24 xl:pt-20'>
        <p className='text-left font-body text-s leading-snug text-white lg:text-sm'>
          {data.text}
        </p>
      </div>
    </div>
  );
}

export default GameplayBlock;

import React, { useState } from 'react';

import Button from '@base/parts/button';
import LocaleSwitch from '@base/elements/locale-switch';
import Markdown from '@base/parts/markdown';

import Logo from '@elements/logo';
import routeSelector from '@/utils/helpers/route-selector';

function NavStartPage({ pageContext, globalData }) {
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const mainNav = globalData?.navData?.mainNav;
  const ctaLinks = globalData?.navData?.ctaLinks;
  const routeSelectorHandler = routeSelector();

  const experimentChoiceData = mainNav[1];
  const websiteChoiceData = mainNav[0];

  // Toggle Mobile Nav
  const mobileClickEvent = (e) => {
    setIsMobileOpen(!isMobileOpen);
  };

  return (
    <header className='start-page-header fixed w-full top-0 z-god transition-all pointer-events-none header-hamburger'>
      <div className='nav start-page-nav flex justify-between px-5 lg:pl-16 lg:pr-0 h-[45px] lg:h-32 relative pointer-events-auto'>
        <div className='flex w-auto items-center relative z-10'>
          <Logo
            globalData={globalData}
            className='nav-logo gatsby-hidden-desktop'
          />
        </div>
        <nav
          className={`gatsby-hidden-desktop absolute left-0 top-0 nav-mobile-hamburger ${isMobileOpen ? 'mobile-open' : ''}`}
          aria-label='Primary Navigation'
        >
          <ul
            className='nav-list'
            aria-label='Primary Navigation'
            role='menubar'
            aria-expanded='true'
          >
            <li
              className='nav-item flex w-full cursor-default flex-col items-center font-bold lg:w-auto shrink-0 null'
              menu-id='nav-item-31'
              role='none'
            >
              <button
                type='button'
                onClick={() => routeSelectorHandler('website', websiteChoiceData, true, pageContext)}
                className='main-link inline-flex items-center justify-between lg:justify-center fill-current w-full uppercase current'
              >
                <div className='nav-link-title'>
                  <span>{websiteChoiceData.text}</span>
                </div>
              </button>
            </li>
            <li
              className='nav-item flex w-full cursor-default flex-col items-center font-bold lg:w-auto shrink-0 null'
              menu-id='nav-item-32'
              role='none'
            >
              <button
                type='button'
                onClick={() => routeSelectorHandler('experiment', experimentChoiceData, true, pageContext)}
                className='main-link inline-flex items-center justify-between lg:justify-center fill-current w-full uppercase'
              >
                <div className='nav-link-title'>
                  <span>
                    {experimentChoiceData.text}
                  </span>
                </div>
              </button>
            </li>
            <li
              className='nav-item nav-item-locales flex w-full cursor-default flex-col items-center font-bold lg:w-auto shrink-0 lg:hidden'
              tabIndex='0'
            >
              <LocaleSwitch
                className='text-white'
                pageContext={pageContext}
                selectorIcon={pageContext.theme?.siteTheme?.header?.LocaleSwitch?.selectorIcon}
                languageIcon={pageContext.theme?.siteTheme?.header?.LocaleSwitch?.languageIcon}
                layout={pageContext.theme?.siteTheme?.header?.LocaleSwitch?.layout}
                title={globalData?.stringsData?.labels?.language}
              />
            </li>
            <li
              className='nav-item nav-item-cta sticky-top-mobile sticky flex flex-col items-center space-y-8 overflow-hidden p-10 pt-20 lg:hidden'
              tabIndex='0'
            >
              {ctaLinks?.map((ctaLink) => (
                <Button
                  id={ctaLink.buttonId}
                  link={ctaLink.link}
                  trigger={ctaLink.trigger}
                  size='lg'
                  icon={ctaLink.icon}
                  pageContext={pageContext}
                  gsEvent={ctaLink?.gsEvent}
                  event={ctaLink.eventAction ? ctaLink.eventAction : ''}
                  eventPage='dispatch_page'
                  eventSection={ctaLink.eventSection || 'nav'}
                >
                  {ctaLink.buttonId && (
                    <span className='hidden'>{ctaLink.buttonId}</span>
                  )}
                  <Markdown>{ctaLink.title}</Markdown>
                </Button>
              ))}
            </li>
          </ul>
          <button
            type='button'
            className='nav-hamburger'
            onClick={mobileClickEvent}
            aria-expanded={isMobileOpen}
          >
            <div className='nav-hamburger-inner' />
          </button>
        </nav>

        <div className='nav-ctas pointer-events-none relative z-10 hidden h-full w-auto flex-row items-center justify-end lg:flex'>
          <LocaleSwitch
            className='pointer-events-auto text-white'
            pageContext={pageContext}
            selectorIcon={pageContext.theme?.siteTheme?.header?.LocaleSwitch?.selectorIcon}
            languageIcon={pageContext.theme?.siteTheme?.header?.LocaleSwitch?.languageIcon}
            layout={pageContext.theme?.siteTheme?.header?.LocaleSwitch?.layout}
            title={globalData?.stringsData?.labels?.language}
          />
          {ctaLinks?.map((ctaLink) => (
            !ctaLink.mobileOnly && (
              <Button
                id={ctaLink.buttonId}
                link={ctaLink.link}
                trigger={ctaLink.trigger}
                size='lg'
                icon={ctaLink.icon}
                pageContext={pageContext}
                gsEvent={ctaLink?.gsEvent}
                event={ctaLink.eventAction ? ctaLink.eventAction : ''}
                eventPage='dispatch_page'
                eventSection={ctaLink.eventSection ? ctaLink.eventSection : 'nav'}
                className='pointer-events-auto ml-8'
              >
                {ctaLink.buttonId && (
                  <span className='hidden'>{ctaLink.buttonId}</span>
                )}
                <span>
                  {ctaLink?.title}
                </span>
              </Button>
            )
          ))}
        </div>
      </div>
    </header>
  );
}

export default NavStartPage;

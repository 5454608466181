/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useEffect, useState } from 'react';
import { useReducedMotion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import useWindowSize from '@base/utils/hooks/useWindowSize';
import Heading from '@base/parts/heading';
import Stores from '@base/elements/store/store-buttons';
import Button from '@base/parts/button';
import AnimatedBackground from '@base/elements/animated-background';
import ConditionalWrapper from '@base/utils/helpers/conditionnal-wrapper';

import Logo from '@elements/logo';
import keyartPosterDesktop from '@assets/videos/keyart-poster.jpg';
import keyartPosterMobile from '@assets/videos/keyart-poster-mobile.jpg';
import keyartDesktopMp4 from '@assets/videos/keyart-desktop.mp4';
import keyartDesktopWebM from '@assets/videos/keyart-desktop.webm';
import keyartMobileMp4 from '@assets/videos/keyart-mobile.mp4';
import keyartMobileWebM from '@assets/videos/keyart-mobile.webm';

function HeroBlock({ data, pageContext, globalData }) {
  const prefersReducedMotion = useReducedMotion();

  // Play/Pause video when in/out of the viewport
  const refVideo = useRef(null);
  const [refVideoWrapper, inViewVideo] = useInView({
    threshold: 0,
  });

  useEffect(() => {
    /* if (refVideo && !prefersReducedMotion) {
      if (inViewVideo) {
        refVideo.current?.play();
      } else {
        refVideo.current?.pause();
      }
    } */
  }, [inViewVideo]);

  // Change Video Source for mobile/desktop
  const [keyartPosterSource, setKeyartPosterSource] = useState(keyartPosterMobile);
  const [keyartSourceWebM, setKeyartSourceWebM] = useState(keyartMobileWebM);
  const [keyartSourceMp4, setKeyartSourceMp4] = useState(keyartMobileMp4);

  const windowSize = useWindowSize();
  useEffect(() => {
    if (windowSize.width <= 991) {
      setKeyartPosterSource(keyartPosterMobile);
      setKeyartSourceWebM(keyartMobileWebM);
      setKeyartSourceMp4(keyartMobileMp4);
    } else {
      setKeyartPosterSource(keyartPosterDesktop);
      setKeyartSourceWebM(keyartDesktopWebM);
      setKeyartSourceMp4(keyartDesktopMp4);
    }
  }, [windowSize]);

  useEffect(() => {
    refVideo?.current?.load();
  }, [keyartPosterSource]);

  return (
    <section ref={refVideoWrapper} className='hero-section flex-col'>
      <AnimatedBackground
        className='hero-background relative left-0 top-0 z-0 h-full w-full overflow-hidden bg-secondary lg:absolute'
        translate={[0, -80]}
        offset={['0vh', '100vh']}
      >
        <AnimatedBackground
          className='hero-background-container relative left-0 top-0 z-0 flex h-full w-full justify-center lg:absolute'
          translate={[0, 80]}
          offset={['0vh', '100vh']}
        >
          <div
            className={`hero-video absolute -top-8 left-0 h-full w-full justify-center overflow-hidden sm:-top-48 lg:top-0 ${inViewVideo && !prefersReducedMotion ? 'in-view' : ''}`}
          >
            <video
              ref={refVideo}
              className='w-full'
              playsInline
              autoPlay={!prefersReducedMotion}
              muted
              poster={keyartPosterSource}
              loop
            >
              <source src={keyartSourceWebM} loading='lazy' />
              <source src={keyartSourceMp4} loading='lazy' />
            </video>
          </div>
        </AnimatedBackground>
      </AnimatedBackground>
      <div className='container lg:relative'>
        <div className='flex h-full w-full flex-col items-center justify-end lg:ml-auto lg:w-3/5 xl:w-1/2 lg:justify-center'>
          <Logo globalData={globalData} className='lg:mb-12 xxl:mb-[44px] xxxxl:mb-24' />
          <div className='hero-section-content relative flex w-full flex-col items-center'>
            {(data.title || data.subtitle) && (
              <div className='relative left-0 mb-10 w-full md:top-0 md:mb-16 md:w-auto'>
                {data.title && (
                  <h1 className='multi-line h4 reversed text-center'>
                    {data.title && (
                      <span className='pointer-events-auto left-0'>
                        {data.title}
                      </span>
                    )}
                    {data.title2 && (
                      <span className='pointer-events-auto right-0'>
                        {data.title2}
                      </span>
                    )}
                  </h1>
                )}
                {data.subtitle && (
                  <Heading
                    text={data.subtitle}
                    tag='h2'
                    className='h4 reversed -mt-2 text-center leading-loose lg:mt-0 xl:leading-relaxed'
                  />
                )}
              </div>
            )}
            <Stores
              pageContext={pageContext}
              layout='logo'
              product='base-standard'
              gameData={globalData.gameData}
              className='flex flex-wrap items-center justify-center lg:mb-8'
              projectOrder
              eventAction={pageContext?.dataLayer?.storeLinkIcon?.event}
              eventPage={pageContext.pageDataId}
              eventSection='home keyart'
              logoOnly
            />
            <ConditionalWrapper
              condition={data?.buttons.length > 1}
              // eslint-disable-next-line react/no-unstable-nested-components
              wrapper={(children) => (
                <div className='flex w-full flex-col items-center justify-center md:flex-row md:space-x-14 lg:w-auto'>
                  {children}
                </div>
              )}
            >
              {data?.buttons?.map((button, i) => (
                <Button
                  link={button.link}
                  key={`ctaBtn-${i}`}
                  trigger={button?.trigger}
                  pageContext={pageContext}
                  className={button.className}
                  eventAction={button?.eventAction}
                  eventPage={pageContext.pageDataId}
                  eventSection='home keyart'
                  gsEvent={button?.gsEvent}
                  trackable
                  videoSettings={{
                    player: button?.player || 'youtube',
                    loop: true,
                    ratingTimeBuffer: button?.ratingTimeBuffer,
                  }}
                >
                  <span>{button?.text}</span>
                </Button>
              ))}
            </ConditionalWrapper>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroBlock;

import React, { useContext } from 'react';
import SVG from 'react-inlinesvg';
import { InView } from 'react-intersection-observer';
import { StaticImage } from 'gatsby-plugin-image';
import { motion, AnimatePresence } from 'framer-motion';

import { PostsContext } from '@base/utils/contexts/posts-context';
import Heading from '@base/parts/heading';
import AnimatedBackground from '@base/elements/animated-background';
import Button from '@base/parts/button';
import Image from '@base/parts/image';

import MediaFilters from '@elements/media-filters';
import playBtn from '@img/icons/play-btn.svg';

function MediasBlock({ data, pageContext, globalData }) {
  const { ctxPostFiltered, ctxPostLoading } = useContext(PostsContext);
  const [postFiltered, setPostFiltered] = ctxPostFiltered;
  const [postLoading, setPostLoading] = ctxPostLoading;

  return (
    <section className='medias-section relative'>
      <div className='custom-content relative z-1 pb-64 pt-16 md:pb-80 md:pt-28'>
        {data.title && (
          <Heading
            text={data.title}
            tag='h1'
            className='h2 lg:forward mb-24 text-center'
          />
        )}
        {data.rows && (
          <MediaFilters
            posts={data.rows}
            enabled
            strings={globalData.stringsLabels}
            className=''
            filterClassName=''
          />
        )}

        <div
          className={`medias-content transition-all duration-500 ${postLoading ? 'opacity-0' : 'opacity-100'}`}
        >
          <AnimatePresence>
            {postFiltered &&
              postFiltered?.map((row) => {
                if (!row?.image) {
                  return '';
                }
                const imageFilePath = row?.image.publicURL.split('/');
                const imageFileName = imageFilePath[imageFilePath.length - 1];
                const imageFileNameClean = imageFileName.substring(0, imageFileName.lastIndexOf('.')) || imageFileName;

                return (
                  <InView triggerOnce>
                    {({ inView, ref, entry }) => (
                      <motion.article
                        ref={ref}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{
                          duration: 0.5,
                          delay: 0.15,
                        }}
                        className={`medias-article medias-article-${row.category}`}
                      >
                        <div
                          className={`medias-article-img-wrapper ${row.category === 'videos' && 'video-button cursor-pointer'}`}
                        >
                          {row.category === 'videos' && row.urlThumbnail ? (
                            <div
                              className={`gatsby-image-wrapper gatsby-image-wrapper-constrained medias-article-img img-video w-full ${inView ? 'in-view' : ''}`}>
                              <Image
                                media={{ url: row.urlThumbnail }}
                                className='img-video left-0 top-0 w-full'
                              />
                            </div>
                          ) : (
                            <Image
                              media={row?.image}
                              className={`medias-article-img w-full ${inView ? 'in-view' : ''}`}
                            />
                          )}
                          {row.category === 'videos' && row?.video && (
                            <Button
                              link={row?.video}
                              trigger='videoModal'
                              pageContext={pageContext}
                              className='medias-article-video-button'
                              eventAction='play'
                              eventPage={pageContext.pageDataId}
                              trackable
                              videoSettings={{
                                player: 'youtube',
                                loop: true,
                                ratingTimeBuffer: '0',
                                disableRatings: true,
                              }}
                            >
                              <SVG
                                cacheRequests
                                preProcessor={(code) => code.replace(
                                  /stroke=".*?"/g,
                                  'stroke="currentColor"',
                                )}
                                src={playBtn}
                                className='play-icon'
                              />
                            </Button>
                          )}
                          {row.category !== 'videos' && row?.image && (
                            <Button
                              link={row?.image}
                              trigger='imageModal'
                              pageContext={pageContext}
                              className='medias-article-image-button'
                            />
                          )}
                          <div
                            className={`medias-article-tag medias-article-tag-${row.category}`}
                          >
                            <span className='relative z-1'>
                              {globalData.stringsLabels[row.category]
                                ? globalData.stringsLabels[row.category]
                                : row.category}
                            </span>
                          </div>
                        </div>
                        <div className='medias-article-content'>
                          <Heading
                            text={row.title}
                            tag='h3'
                            className='plain'
                          />
                          <div className='flex justify-end pt-16'>
                            {row.category !== 'videos' && (
                              <Button
                                link={row?.image.publicURL}
                                title={imageFileNameClean}
                                trigger='download'
                                pageContext={pageContext}
                                label={globalData.stringsActions?.download}
                                className='button-tertiary button-download'
                                eventAction='download'
                                eventPage={pageContext.pageDataId}
                                trackable
                              />
                            )}
                            {row.category === 'videos' && row?.video && (
                              <Button
                                link={row?.video}
                                trigger='videoModal'
                                pageContext={pageContext}
                                className='button-tertiary button-play'
                                eventAction='play'
                                eventPage={pageContext.pageDataId}
                                trackable
                                videoSettings={{
                                  player: 'youtube',
                                  loop: true,
                                  disableRatings: true,
                                }}
                              >
                                <span>{globalData.stringsActions?.play}</span>
                                <SVG
                                  cacheRequests
                                  preProcessor={(code) => code.replace(
                                    /stroke=".*?"/g,
                                    'stroke="currentColor"',
                                  )}
                                  src={playBtn}
                                  className='play-icon no-hover'
                                />
                              </Button>
                            )}
                          </div>
                        </div>
                      </motion.article>
                    )}
                  </InView>
                );
              })}
          </AnimatePresence>
        </div>
      </div>

      <AnimatedBackground
        className='medias-background relative left-0 top-0 z-under h-full w-full overflow-hidden bg-black lg:absolute'
        translate={[0, -80]}
        offset={['0vh', '100vh']}
      >
        <AnimatedBackground
          className='medias-background-container relative left-0 top-0 z-0 flex h-full w-full justify-center lg:absolute'
          translate={[0, 80]}
          offset={['0vh', '100vh']}
        >
          <StaticImage
            src='../../assets/img/backgrounds/medias-background.jpg'
            alt=''
            className='medias-background gatsby-absolute left-0 top-0 h-full w-full overflow-hidden'
            placeholder='blurred'
            loading='eager'
            role='presentation'
          />
        </AnimatedBackground>
      </AnimatedBackground>
    </section>
  );
}

export default MediasBlock;

import React, { useContext, useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import { PostsContext } from '@base/utils/contexts/posts-context';
import e from 'cors';

function MediaFilters({
  quantity,
  posts,
  enabled,
  className,
  filterClassName,
  strings,
}) {
  const { ctxPostLoading, ctxPostCategory, ctxPostFiltered } = useContext(PostsContext);
  const [postFiltered, setPostFiltered] = ctxPostFiltered;
  const [postLoading, setPostLoading] = ctxPostLoading;
  const [postCategory, setPostCategory] = ctxPostCategory;

  // Initial State with URL filtering
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const categoryParam = params.get('category');
    if (categoryParam && categoryParam !== null) {
      setPostCategory(categoryParam);
    } else {
      setPostCategory('all');
    }
  }, []);

  // Set number of post to load
  const numberOfPosts = quantity || 1000;

  function filterPosts() {
    let object = [];
    if (posts) {
      if (postCategory === 'all') {
        object = posts;
      } else {
        posts.forEach((post) => {
          if (post.category === postCategory) {
            object.push(post);
          }
        });
      }
    }
    return object;
  }
  // Filter by Category (Base Game or Add-Ons/DLC)
  function filterCategories() {
    const object = [
      [strings.all, 'all'],
      // [strings.keyart, 'keyart'],
      // [strings.wallpaper, 'wallpaper'],
      [strings.images, 'images'],
      [strings.videos, 'videos']
    ];
    return object;
  }
  const filterCategoriesOptions = filterCategories();

  const categoryFilter = (value) => {
    if (value !== postCategory) {
      setPostLoading(true);
      setTimeout(() => {
        setPostCategory(value);
      }, 500);
    }
  };

  function filterbyCategory() {
    async function loadViews() {
      new Promise((resolve) => {
        const object = filterPosts();
        resolve(object);
      }).then((object) => {
        const numberedPosts = [...object.slice(0, numberOfPosts)];
        setPostFiltered(numberedPosts);
        setTimeout(() => {
          setPostLoading(false);
        }, 500);
      });
    }
    if (postCategory !== null) {
      loadViews();
    }
  }
  useEffect(() => {
    filterbyCategory();
  }, [postCategory]);

  return (
    enabled === true && (
      <div>
        <div className={`media-filters w-full ${className} flex flex-row flex-wrap`}>
          {filterCategoriesOptions.map((filter, i) => (
            <button
              type='button'
              className={`mb-4 media-filters-button medias-article-tag-${filter[1]} ${filterClassName} ${filter[1] === postCategory ? 'filter-active' : ''}`}
              value={filter[1]}
              onClick={() => categoryFilter(filter[1])}
              key={filter[1] + i}
            >
              <span className='relative z-1'>{filter[0]}</span>
            </button>
          ))}
        </div>
      </div>
    )
  );
}

export default MediaFilters;

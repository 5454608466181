import React, { useEffect } from 'react';
import SVG from 'react-inlinesvg';

import useWindowSize from '@base/utils/hooks/useWindowSize';
import Button from '@base/parts/button';

import arrow from '@img/icons/arrow.svg';
import plusIcon from '@img/icons/plus.svg';
import GameModal from './game-modal';

function GameAccordion({ data, pageContext, globalData }) {
  const windowSize = useWindowSize();
  useEffect(() => {
    if (windowSize.width > 991) {
      const deluxeContent = document.getElementById('accordion-base-deluxe');
      const panelHeight = deluxeContent.scrollHeight || 340;
      const accordionContainers = document.querySelectorAll('.accordion-content');
      accordionContainers.forEach((container) => {
        const contentBox = container;
        if (contentBox.classList.contains('active')) {
          contentBox.style.height = `${panelHeight}px`;
        }
      });
    }
  }, [windowSize]);

  const modalRewardsContent = () => (
    <GameModal data={data} type='rewards' strings={globalData.stringsAll} />
  );

  const openAccordion = (target) => {
    const deluxeContent = document.getElementById('accordion-base-deluxe');
    const panelHeight = deluxeContent.scrollHeight || 340;

    if (windowSize.width > 991) {
      const accordionBtns = document.querySelectorAll('.accordion-button');
      accordionBtns.forEach((button) => {
        if (button.classList.contains('active')) {
          button.classList.remove('active');
        } else {
          button.classList.add('active');
        }
      });

      const accordionContainers = document.querySelectorAll('.accordion-content');
      accordionContainers.forEach((container) => {
        const contentBox = container;
        if (contentBox.classList.contains('active')) {
          contentBox.classList.remove('active');
          contentBox.style.height = '0px';
        } else {
          contentBox.classList.add('active');
          contentBox.style.height = `${panelHeight + 15}px`;
        }
      });
    } else {
      const accordionBtn = document.getElementById(`accordion-btn-${target}`);
      const accordionContainer = document.getElementById(`accordion-${target}`);
      if (accordionContainer.classList.contains('active')) {
        accordionBtn.classList.remove('active');
        accordionContainer.classList.remove('active');
      } else {
        accordionBtn.classList.add('active');
        accordionContainer.classList.add('active');
      }
    }
  };

  return (
    <div className='accordion'>
      <div className='accordion-row'>
        <Button
          id={`accordion-btn-${data.gameID}`}
          trigger='clickAction'
          pageContext={pageContext}
          className='accordion-button button-learnmore flex !w-full justify-between'
          trackable
          eventAction='drop_down_dbd_rewards'
          eventPage={pageContext.pageDataId}
          onClick={() => openAccordion(data.gameID)}
        >
          <h3 className='plain with-separator mb-8 w-full pr-12 text-left !text-base normal-case leading-[1.1]'>
            {globalData.stringsLabels.dbdOwnerRewards}
          </h3>
          <SVG
            cacheRequests
            preProcessor={(code) => code.replace(/fill=".*?"/g, 'fill="currentColor"')}
            src={arrow}
            className='accordion-custom-button !w-5'
          />
        </Button>
        <div id={`accordion-${data.gameID}`} className='accordion-content'>
          <ul className='px-10 text-sm'>
            {data.dbdRewards?.map((reward, l) => (
              <li key={`gameReward_${data.gameID}_${l}`}>{reward.title}</li>
            ))}
          </ul>
          <Button
            trigger='dynamicModal'
            pageContext={pageContext}
            className='button-learnmore mb-5 mt-8 !text-base'
            modalContent={{
              className: 'modal-dynamic-rewards',
              content: modalRewardsContent,
            }}
            trackable
            eventAction='pop_up_dbd_rewards'
            eventPage={pageContext.pageDataId}
          >
            <SVG cacheRequests src={plusIcon} className='mr-2' />
            {globalData.stringsAll.cta.viewRewards}
          </Button>
          <p className='text-xs'>
            {globalData.stringsLabels.rewardsDisclaimer}
          </p>
        </div>
      </div>
    </div>
  );
}

export default GameAccordion;

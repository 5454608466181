import React, { useEffect, useState } from 'react';
import { useReducedMotion } from 'framer-motion';
import { StaticImage } from 'gatsby-plugin-image';

import useWindowSize from '@base/utils/hooks/useWindowSize';
import Heading from '@base/parts/heading';
import AnimatedBackground from '@base/elements/animated-background';

import GameCard from '../elements/game-card';

function BuyBlock({ data, pageContext, globalData }) {
  const [gameInclusions, setGameInclusions] = useState({});
  const prefersReducedMotion = useReducedMotion();
  const [isMobile, setIsMobile] = useState(0);
  const windowSize = useWindowSize();
  useEffect(() => {
    if (windowSize.width <= 991) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [windowSize]);

  useEffect(() => {
    const inclusions = {
      standard: [],
      deluxe: [],
    };
    globalData.gameData?.gameLinks?.forEach((game) => {
      if (game.gameID === 'base-standard') {
        inclusions.standard.push(game.inclusions);
      }
      if (game.gameID === 'base-deluxe') {
        inclusions.deluxe.push(game.inclusions);
      }
    });
    setGameInclusions(inclusions);
  }, []);

  return (
    <section className='buy-section flex-col'>
      <div className='custom-content relative z-1 !px-10 lg:!px-0'>
        {data.title && (
          <Heading
            text={data.title}
            tag='h1'
            className='h2 lg:forward mb-12 text-center leading-tight lg:mb-20'
          />
        )}
        <div className='flex flex-col space-y-10 lg:flex-row lg:space-x-14 lg:space-y-0'>
          {globalData.gameData.gameLinks?.map((game, i) => (
            <GameCard
              game={game}
              index={i}
              pageContext={pageContext}
              globalData={globalData}
              inclusions={gameInclusions}
            />
          ))}
        </div>
      </div>
      <AnimatedBackground
        className='gameplay-background relative left-0 top-0 z-under h-full w-full overflow-hidden bg-black lg:absolute'
        translate={[0, isMobile ? 0 : -80]}
        offset={['0vh', '100vh']}
      >
        <AnimatedBackground
          className={`gameplay-background-container relative left-0 top-0 z-0 flex h-full w-full justify-center lg:absolute ${!prefersReducedMotion ? 'mt-0' : 'mt-0 lg:-mt-32'}`}
          translate={[0, isMobile ? 0 : 100]}
          offset={['0vh', '100vh']}
        >
          <StaticImage
            src='../../assets/img/backgrounds/gameplay-background.jpg'
            alt=''
            className='gameplay-background gatsby-absolute left-0 top-0 h-full w-full overflow-hidden'
            placeholder='blurred'
            loading='eager'
            role='presentation'
          />
        </AnimatedBackground>
      </AnimatedBackground>
    </section>
  );
}

export default BuyBlock;

import React from 'react';
import SVG from 'react-inlinesvg';
import { useInView } from 'react-intersection-observer';
import { StaticImage } from 'gatsby-plugin-image';

import Heading from '@base/parts/heading';
import Image from '@base/parts/image';
import AnimatedBackground from '@base/elements/animated-background';
import Button from '@base/parts/button';

import separator from '@img/ui/separator.svg';

function TextImageBlock({ data, pageContext }) {
  return (
    <section className='text-image-section relative'>
      <AnimatedBackground
        className='text-image-bg-mask-2 pointer-events-none absolute left-0 top-0 z-under h-full w-full'
        translate={[-100, -20]}
        offset={['-120vh', '-10vh']}
      >
        <AnimatedBackground
          className='text-image-bg-mask pointer-events-none absolute -top-40 left-0 z-under h-[calc(100%+10rem)] w-full'
          translate={[0, 0]}
          offset={['50vh', '100vh']}
        >
          <AnimatedBackground
            className='pointer-events-none absolute -top-40 left-0 z-under h-[calc(100%+10rem)] w-full -translate-y-[84px] bg-secondary'
            translate={[100, 20]}
            offset={['-120vh', '-10vh']}
          >
            <StaticImage
              src='../../assets/img/backgrounds/text-image-background.jpg'
              alt=''
              placeholder='blurred'
              loading='lazy'
              className='text-image-bg gatsby-hidden-mobile opacity-70'
              role='presentation'
            />
            <StaticImage
              src='../../assets/img/backgrounds/text-image-mobile-background.jpg'
              alt=''
              placeholder='blurred'
              loading='lazy'
              className='text-image-bg gatsby-hidden-desktop opacity-70'
              role='presentation'
            />
          </AnimatedBackground>
        </AnimatedBackground>
      </AnimatedBackground>
      <div className='custom-content text-image-content relative pt-16 md:pt-28'>
        <div className='text-center lg:mb-20'>
          <Heading
            text={data.title}
            tag='h3'
            className='lg:forward h2 text-image-title'
          />
          <p className='dark-bg mx-auto w-full pt-12 leading-tight text-white lg:w-3/4 lg:pt-24'>
            {data.text}
          </p>
          <SVG
            cacheRequests
            preProcessor={(code) => code.replace(/stroke=".*?"/g, 'stroke="currentColor"')}
            src={separator}
            className='gatsby-hidden-desktop mt-12 h-2 w-full'
          />
        </div>
        <div>
          {data.rows.map((row, i) => (
            <TextImageRow data={row} index={i} />
          ))}
        </div>
        <div className='text-image-buttons mt-16 lg:mt-24 flex justify-center'>
          {data?.buttons?.map((button, i) => (
            <Button
              link={button.link}
              key={`text-image-btn-${i}`}
              trigger={button?.trigger}
              pageContext={pageContext}
              className={button?.className}
              eventAction={button?.eventAction}
              eventPage={pageContext.pageDataId}
              eventSection='home text image section'
            >
              <span>{button?.text}</span>
            </Button>
          ))}
        </div>
      </div>
    </section>
  );
}

function TextImageRow({ data, index }) {
  const [ref, inView] = useInView({
    threshold: 0.75,
    triggerOnce: true,
  });

  const [ref2, inView2] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  return (
    <div
      ref={ref}
      className='text-image-row flex-col items-center lg:flex-row'
      key={`text-image-row-${index}`}
    >
      <div
        ref={ref2}
        className={`text-image-row-image order-2 lg:order-1 ${inView ? 'in-view' : ''}`}
      >
        <div className='text-image-row-image-content polaroid'>
          <Image
            media={data?.image}
            alt={data?.title}
            className='image w-full'
          />
          {inView2 && (
            <StaticImage
              src='../../assets/img/content/void.jpg'
              alt=''
              placeholder='blurred'
              loading='lazy'
              className='image-border'
              role='presentation'
            />
          )}
        </div>
      </div>
      <div className='text-image-row-text order-1 flex flex-col items-center lg:order-2 lg:items-start'>
        <Heading
          text={data.title}
          tag='h4'
          className='h3 pb-12 pt-12 text-center lg:pt-0'
        />
        <p className='dark-bg mb-12 text-center leading-tight text-white lg:mb-0 lg:text-left'>
          {data.text}
        </p>
      </div>
    </div>
  );
}

export default TextImageBlock;

/*
  PAGE TEMPLATE
  A template to display fully editable pages
*/

import React, { useContext, useEffect, useState } from 'react';
import { graphql } from 'gatsby';

import PostContextProvider from '@base/utils/contexts/posts-context';
import PageLayout from '@base/layout/page-layout';
import Blocks from '@base/layout/blocks';

import { getGeneralData } from '@api/general-data';
import { getModalBuy } from '@elements/modal-buy';
import StartPage from '@elements/start-page/start-page';
import Crm from '@elements/crm/crm';
import { GateContext } from '@/utils/contexts/gate-context';
import triggerPageView from '@/utils/helpers/trigger-page-view';
import urlParameters from '@/utils/helpers/url-parameters';

function DynamicPage({ data, pageContext }) {
  const [dispatchPage, setDispatchPage] = useState(false);
  const { firstTime } = useContext(GateContext);
  const [isFirstTime, setFirstTime] = firstTime;
  const generalData = getGeneralData(pageContext, data);
  const minifiedPageContext = generalData.pageContext;
  const { globalData } = generalData;
  const { modalData } = generalData;
  const { title, parentPage, disablePageBreadcrumbs } = data.pageData;
  const { contentSections } = data.blocksData;

  // Use the project's own "Buy Game" Modal Component
  const modalTitle = minifiedPageContext?.buyModalTitle;
  const modalSubTitle = minifiedPageContext?.buyModalSubTitle;

  const customModalBuy = getModalBuy();

  // Trigger events based on URL parameters
  const urlParametersHandler = urlParameters();
  useEffect(() => {
    urlParametersHandler(minifiedPageContext);
  }, []);

  // Dispatch Page
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const dispatchPageParam = params?.has('dispatch');

    if (dispatchPageParam) {
      setDispatchPage(true);
    } else {
      triggerPageView(data.pageData.pageDataId, pageContext);
    }
  }, [isFirstTime]);

  return (
    <>
      {dispatchPage && (
        <StartPage
          globalData={generalData.globalData}
          pageContext={generalData.pageContext}
        />
      )}
      <PageLayout
        pageContext={minifiedPageContext}
        modalData={modalData}
        breadcrumbs={{
          enableGlobalBreadcrumbs: true,
          disablePageBreadcrumbs,
          postTitle: title,
          postParent: [parentPage],
          strings: { back: globalData.stringsActions.back, home: 'Home' },
        }}
        modalBuyComponent={() => customModalBuy(pageContext, modalData, modalTitle, modalSubTitle)}
      >
        <PostContextProvider>
          <Blocks
            pageContext={minifiedPageContext}
            globalData={globalData}
            blocks={contentSections}
          />
        </PostContextProvider>
        <Crm pageContext={minifiedPageContext} globalData={globalData} />
      </PageLayout>
    </>
  );
}
export default DynamicPage;

export const query = graphql`
  query DynamicPageQuery($id: String!) {
    pageData: pagesJson(id: { eq: $id }) {
      dataId
      pageDataId
      draft
      title
      slug
      locale
      disablePageBreadcrumbs
      disableNewsletterSignup
      disableBuyGameSection
      disableAgeGate
      parentPage {
        slug
        title
      }
      metadata {
        metaTitle
        metaDescription
        canonicalURL
        disablePageIndexing
        shareImage {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
          publicURL
        }
      }
      localizations {
        dataId
        locale
        slug
      }
    }
    blocksData: pagesJson(id: { eq: $id }) {
      contentSections {
        ...Blocks
      }
    }
  }
`;
export { Head } from '@base/layout/head';

import React, { useContext, useEffect, forwardRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { GateContext } from '@/utils/contexts/gate-context';
import routeSelector from '@/utils/helpers/route-selector';

const ChoiceDial = forwardRef((props, ref) => {
  const { data } = props;
  const { pageContext } = props;
  const routeSelectorHandler = routeSelector();
  const { active, selectRoute, mobileState } = useContext(GateContext);
  const [isMobile, setIsMobile] = mobileState;
  const [selectedRoute, setSelectedRoute] = selectRoute;
  const [activeSelection, setActiveSelection] = active;
  const experimentChoiceData = data.find((x) => x.id === 'experiment');
  const websiteChoiceData = data.find((x) => x.id === 'website');

  // Make the Choice handle follow mouse movements
  useEffect(() => {
    const handle = document.querySelector('.choice-dial-handle');
    if (!isMobile) {
      if (handle) {
        const handleBoundingRect = handle.getBoundingClientRect();
        const handleCenter = {
          x: handleBoundingRect.left + handleBoundingRect.width / 2,
          y: handleBoundingRect.top + handleBoundingRect.height / 2,
        };
        document.addEventListener('mousemove', (e) => {
          let angle = Math.atan2(e.pageX - handleCenter.x, -(e.pageY - handleCenter.y)) * (180 / Math.PI);
          if (angle <= '-60') {
            angle = '-90';
          }
          if (angle >= '60') {
            angle = '90';
          }
          handle.style.transform = `translate(-50%, -50%) rotate(${angle}deg)`;
        });
      }
    }
  });

  // Choice highlight effect
  const choiceHandler = (choice, status) => {
    if (status === 'enter') {
      setSelectedRoute(choice);
    }
    if (status === 'leave') {
      setSelectedRoute(null);
    }
  };

  return (
    <div className='text-white flex flex-col md:flex-row justify-center items-start w-full md:w-auto h-full md:h-auto px-5 md:px-0'>
      <button
        type='button'
        className={`choice-button pointer-events-auto order-3 md:order-1 flex px-0 md:flex-col items-center min-h-[50%] h-[18rem] md:min-h-none md:h-auto
          ${selectedRoute === 'experiment' ? 'active' : (selectedRoute !== null ? 'inactive' : '')}
          ${activeSelection === 'experiment' ? 'stay-active' : ''}  
        `}
        onClick={() => routeSelectorHandler('experiment', experimentChoiceData, isMobile, pageContext)}
        onMouseEnter={() => choiceHandler('experiment', 'enter')}
        onMouseLeave={() => choiceHandler('experiment', 'leave')}
        onFocus={() => choiceHandler('experiment', 'enter')}
        onBlur={() => choiceHandler('experiment', 'leave')}
      >
        <div className='w-[60%] md:w-full px-5 md:px-0 relative z-1'>
          {experimentChoiceData.buttonText && (
            <div className='button button-secondary relative z-10 inline-flex items-center justify-center'>
              <span>
                <StaticImage
                  src='../../../assets/img/icons/arrow.png'
                  alt=''
                  className='icon-arrow w-[1.3rem] h-[1.3rem] -rotate-90 mr-2 hidden md:flex'
                  placeholder='blurred'
                  loading='lazy'
                />
                {experimentChoiceData.buttonText}
              </span>
            </div>
          )}
          <p className='md:text-right mb-0 pt-6 lg:pt-8'>{experimentChoiceData?.text}</p>
        </div>
        <StaticImage
          src='../../../assets/img/dispatch-page-choice-interactive.jpg'
          alt='Investigate Cedar Steel'
          className='choice-button-image choice-button-image-right gatsby-hidden-desktop w-[60%] h-full -ml-[10%]'
          placeholder='#000000'
          loading='lazy'
        />
      </button>
      <div className='choice-dial relative pointer-events-auto hidden md:flex order-2 h-1/2 md:h-auto' ref={ref}>
        <StaticImage
          src='../../../assets/img/ui/choice-button-handle.png'
          alt='Selector Handle'
          className={`choice-dial-handle gatsby-absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-1 w-[90px] choice-${selectedRoute}`}
          placeholder='blurred'
          loading='lazy'
        />
        <StaticImage
          src='../../../assets/img/ui/choice-button.png'
          alt='Selector'
          className=' w-[115px]'
          placeholder='blurred'
          loading='lazy'
        />
      </div>
      <button
        type='button'
        className={`choice-button pointer-events-auto order-1 md:order-3 px-0 flex md:flex-col items-center md:items-start min-h-[50%] h-[18rem] md:min-h-none md:h-auto mb-5 md:mb-0
          ${selectedRoute === 'website' ? 'active' : (selectedRoute !== null ? 'inactive' : '')}
          ${activeSelection === 'website' ? 'stay-active' : ''}  
        `}
        onClick={() => routeSelectorHandler('website', websiteChoiceData, isMobile, pageContext)}
        onMouseEnter={() => choiceHandler('website', 'enter')}
        onMouseLeave={() => choiceHandler('website', 'leave')}
        onFocus={() => choiceHandler('website', 'enter')}
        onBlur={() => choiceHandler('website', 'leave')}
      >
        <StaticImage
          src='../../../assets/img/dispatch-page-choice-website.jpg'
          alt='The Casting of Frank Stone Website'
          className='choice-button-image choice-button-image-left gatsby-hidden-desktop w-[60%] h-full -mr-[10%]'
          placeholder='#000000'
          loading='lazy'
        />
        <div className='w-[60%] md:w-full px-5 md:px-0 relative z-1'>
          {websiteChoiceData.buttonText && (
            <div className='button button-secondary relative z-10 inline-flex items-center justify-center'>
              <span>
                {websiteChoiceData.buttonText}
                <StaticImage
                  src='../../../assets/img/icons/arrow.png'
                  alt=''
                  className='icon-arrow w-[1.3rem] h-[1.3rem] rotate-90 ml-2 hidden md:flex'
                  placeholder='blurred'
                  loading='lazy'
                />
              </span>
            </div>
          )}
          <p className='mb-0 pt-6 lg:pt-8'>{websiteChoiceData?.text}</p>
        </div>
      </button>
    </div>
  );
});

export default ChoiceDial;

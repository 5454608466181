import React, { useContext } from 'react';

import { GeolocalizationContext } from '@base/utils/contexts/geolocalization-context';
import AgeGate from '@base/elements/age-gate/age-gate';

import closeIcon from '@img/icons/close.svg';

function PageAgeGate(props) {
  const { pageContext } = props;
  const { globalData } = props;

  // Game Rating & Age Gate Informations
  const { ageGate } = useContext(GeolocalizationContext);
  const [ageGateSettings, setAgeGateSettings] = ageGate;
  const ageGateData = props.data;
  const stringsDates = globalData?.stringsData?.dates;
  const stringsErrors = globalData?.stringsData?.errors;

  return (
    ageGateSettings && ageGateSettings.enabled && ageGateData && (
      <AgeGate
        dataID='startPage'
        data={ageGateData}
        type={props.isDirect ? 'modal' : 'video'}
        strings={{ errors: stringsErrors, dates: stringsDates }}
        dateFormat={pageContext?.locale === 'ja' ? 'yyyy-mm-dd' : 'dd-mm-yyyy'}
        closeIcon={closeIcon}
        fullPage={props.isDirect}
        pageContext={pageContext}
        eventSection={props.eventSection}
      />
    )
  );
}
export default PageAgeGate;

import React, { useState } from 'react';
import { useReducedMotion } from 'framer-motion';
import SVG from 'react-inlinesvg';
import { useInView } from 'react-intersection-observer';
import { Swiper, SwiperSlide } from 'swiper/react';

import trackLink from '@base/utils/helpers/tracking/track-link';
import Heading from '@base/parts/heading';
import Button from '@base/parts/button';
import Image from '@base/parts/image';

import arrow from '@assets/img/ui/arrow-polaroid.svg';
import arrowHover from '@assets/img/ui/slider-hover-circle.svg';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

function GalleryBlock({ data, pageContext }) {
  const prefersReducedMotion = useReducedMotion();
  const [swiper, setSwiper] = useState(null);
  const [swiper2, setSwiper2] = useState(null);

  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  // Send tracking data to the dataLayer
  const trackingData = {
    pageContext,
    event: 'screenshots_gallery',
    eventPage: pageContext.pageDataId,
    eventSection: 'game overview screenshots',
  };

  const setSwiperPrev = () => {
    swiper.slidePrev();
    swiper2.slideNext();
    trackLink(trackingData);
  };

  const setSwiperNext = () => {
    swiper.slideNext();
    swiper2.slidePrev();
    trackLink(trackingData);
  };

  // Slide Counter
  function updSwiperNumericPagination(realIndex) {
    const slideAmount = data.rows.length;
    const counter = document.querySelector('.swiper-counter');

    if (counter && slideAmount) {
      document.querySelector('.swiper-counter').innerHTML = `<span class="count">${realIndex + 1}</span>/<span class="total">${slideAmount}</span>`;
    }
  }

  const updateIndex = (swiperInstance) => {
    if (swiperInstance === null) return;
    const currentSlide = swiperInstance?.realIndex;
    updSwiperNumericPagination(currentSlide);
  };

  function setupSeparator() {
    let content = '';
    for (let i = 0; i < 5; i++) {
      if (i === 2) content += '<div class="separator"><span></span></div>';
      else content += '<div class="separator"></div>';
    }
    return {
      __html: content,
    };
  }

  // Next/Prev Buttons Hover
  const navBtnHover = (event, direction) => {
    const hoverClass = `hover-${direction}`;
    document?.querySelector('.swiper-container')?.classList.remove('in-view');
    document?.querySelector('.swiper-container')?.classList.add('in-view-played');
    if (event === 'enter') {
      document?.querySelector('.gallery-slider-reverse')?.classList.add(hoverClass);
      document?.querySelector('.gallery-slider-forward')?.classList.add(hoverClass);
    } else if (event === 'leave') {
      document?.querySelector('.gallery-slider-reverse')?.classList.remove(hoverClass);
      document?.querySelector('.gallery-slider-forward')?.classList.remove(hoverClass);
    }
  };

  // Slides Image
  const slidesForward = [...data.rows]?.reverse();
  const slidesReverse = data?.rows;

  return (
    <section ref={ref} className='gallery-section relative pt-0 lg:pb-20'>
      <div className='custom-content gallery-content relative pt-16 lg:pt-0'>
        <div className='relative z-1 text-center lg:-mb-16'>
          <Heading
            text={data.title}
            tag='h3'
            className='lg:forward gallery-title with-separator separator-forward'
          />
        </div>
        <div
          className={`swiper-container relative flex space-x-4 lg:-rotate-[4deg] ${inView && !prefersReducedMotion ? 'in-view' : ''} ${prefersReducedMotion ? 'no-animation' : ''}`}
        >
          <div className='swiper-content gallery-slider-reverse'>
            {data?.rows && (
              <Swiper
                onSwiper={setSwiper}
                slidesPerView={2.25}
                spaceBetween={10}
                direction='vertical'
                loop
                autoHeight
                onActiveIndexChange={updateIndex}
                centeredSlides
                allowTouchMove
                initialSlide={0}
                breakpoints={{
                  991: {
                    centeredSlides: false,
                    allowTouchMove: false,
                    initialSlide: 3,
                  },
                }}
              >
                {slidesReverse?.map((row, i) => (
                  <SwiperSlide>
                    <div className='swiper-content-slide flex flex-row items-center justify-center bg-black'>
                      <div className='swiper-img-decoration mr-2 h-24 w-16 rounded-2xl xl:h-40 xl:w-24' />
                      <div className='swiper-content-container-img flex h-full w-full items-center justify-center overflow-hidden rounded-3xl'>
                        <Button
                          link={row?.image}
                          trigger='imageModal'
                          key={`swiper-1-image-${i}`}
                          overwrite
                          className='swiper-img-button flex h-full w-full cursor-pointer items-center justify-center p-0'
                        >
                          <Image
                            media={row?.image}
                            className='swiper-content-img h-full w-full'
                          />
                        </Button>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
          <div className='swiper-content gallery-slider-forward hidden lg:block'>
            {data?.rows && (
              <Swiper
                onSwiper={setSwiper2}
                slidesPerView={2.25}
                centeredSlides
                spaceBetween={10}
                direction='vertical'
                loop
                autoHeight
                initialSlide={slidesForward ? slidesForward.length - 1 : 0}
                allowTouchMove={false}
              >
                {slidesForward?.map((row, i) => (
                  <SwiperSlide>
                    <div className='swiper-content-slide flex flex-row items-center justify-center bg-black'>
                      <div className='swiper-img-decoration mr-2 h-24 w-16 rounded-2xl xl:h-40 xl:w-24' />
                      <div className='swiper-content-container-img flex h-full w-full items-center justify-center overflow-hidden rounded-3xl'>
                        <Button
                          link={row?.image}
                          trigger='imageModal'
                          key={`swiper-2-image-${i}`}
                          overwrite
                          className='swiper-img-button flex h-full w-full cursor-pointer items-center justify-center p-0'
                        >
                          <Image
                            media={row?.image}
                            className='swiper-content-img h-full w-full'
                          />
                        </Button>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
          <div className='swiper-side flex flex-row'>
            <div className='swiper-controls flex h-full w-20 flex-col items-center justify-center px-2 md:w-28'>
              <button
                type='button'
                className='w-full cursor-pointer'
                onClick={setSwiperPrev}
                onMouseEnter={() => navBtnHover('enter', 'prev')}
                onMouseLeave={() => navBtnHover('leave', 'prev')}
              >
                <SVG
                  cacheRequests
                  src={arrow}
                  className='swiper-control'
                />
                <SVG
                  cacheRequests
                  src={arrowHover}
                  className='swiper-control-hover'
                />
              </button>
              {data?.rows && (
                <p className='swiper-counter py-4 font-display text-base text-white md:text-lg'>
                  1/
                  {data.rows.length}
                </p>
              )}
              <button
                type='button'
                className='w-full cursor-pointer'
                onClick={setSwiperNext}
                onMouseEnter={() => navBtnHover('enter', 'next')}
                onMouseLeave={() => navBtnHover('leave', 'next')}
              >
                <SVG
                  cacheRequests
                  src={arrow}
                  className='swiper-control reverse'
                />
                <SVG
                  cacheRequests
                  src={arrowHover}
                  className='swiper-control-hover reverse'
                />
              </button>
            </div>

            <div className='swiper-decorations w-16 lg:w-20 pl-8'>
              <div className='swiper-decorations-bg h-full w-full'>
                <div
                  className='swiper-decorations-bg-content'
                  dangerouslySetInnerHTML={setupSeparator()}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='mt-0 flex justify-center'>
          {data?.buttons?.map((button, i) => (
            <Button
              link={button.link}
              key={`text-image-btn-${i}`}
              pageContext={pageContext}
              trigger={button?.trigger}
              className={button?.className}
              eventAction={button?.eventAction}
              eventPage={pageContext.pageDataId}
              eventSection='game overview screenshots'
              gsEvent={button?.gsEvent}
              trackable
            >
              <span>{button?.text}</span>
            </Button>
          ))}
        </div>
      </div>
      <div className='gallery-bg-mask pointer-events-none absolute left-0 z-under h-[calc(100%+14rem)] w-full bg-black' />
    </section>
  );
}

export default GalleryBlock;

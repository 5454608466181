import { useContext, useEffect, useCallback } from 'react';

import { GeolocalizationContext } from '@base/utils/contexts/geolocalization-context';

import { GateContext } from '@/utils/contexts/gate-context';

const ageValidation = () => {
  const { ageGate, legalAge } = useContext(GeolocalizationContext);
  const [isLegalAge, setLegalAge] = legalAge;
  const [ageGateSettings, setAgeGateSettings] = ageGate;

  const { active, selectRoute } = useContext(GateContext);
  const [activeSelection, setActiveSelection] = active;
  const [selectedRoute, setSelectedRoute] = selectRoute;

  const ageGateHandler = useCallback((route) => {
    if (ageGateSettings && ageGateSettings.enabled && !isLegalAge) {
      setActiveSelection(route);
      setAgeGateSettings((prev) => ({
        ...prev,
        displayed: true,
        usage: 'startPage',
      }));
    }
  }, []);

  // Remove the Choice Highlight when closing the Age Gate
  useEffect(() => {
    if (!ageGateSettings.displayed) {
      setSelectedRoute(null);
      setActiveSelection(null);
    }
  }, [ageGateSettings]);

  return ageGateHandler;
};
export default ageValidation;
